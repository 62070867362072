import Core from './Core'
import * as THREE from 'three'
import { loaderManagerPromise } from './helpers/loadingManager'
import createPromiseObj from './helpers/createPromiseObj'
import createPromiseCubeTexture from './helpers/createPromiseCubeTexture'
import EasedMouse from './helpers/EasedMouse'

import maskModel from '~/media/easterEgg/mask.obj'

class App extends Core {
  constructor (canvas) {
    super(canvas)

    this.raf = undefined
    this.mask = undefined
    this.light = undefined
    this.mousePos = { x: 0, y: 0 }
    // this.easedMouse = new EasedMouse()

    this.load()
  }

  load () {
    const promiseLoadingMask = createPromiseObj(maskModel)
    const promiseLoadingCubeTexture = createPromiseCubeTexture(
      'media/easterEgg/cubeTexture'
    )

    Promise.all([
      promiseLoadingMask,
      promiseLoadingCubeTexture,
      loaderManagerPromise,
    ]).then(resources => {
      this.init(resources)
    })
  }

  init (resources) {
    const [meshMask, cubeTexture] = resources
    this.mask = this.initMask(meshMask, cubeTexture)
    this.scene.add(this.mask)

    this.emit('ready')
  }

  initMask (mesh, cubeTexture) {
    const mat = new THREE.MeshPhysicalMaterial({
      envMap: cubeTexture,
      color: 0xffffff,
      flatShading: true,
      envMapIntensity: 3,
      roughness: 0.2,
      clearCoatRoughness: 1.0,
    })

    mesh.material = mat
    mesh.scale.x += 4
    return mesh
  }

  rotateMask (x, y) {
    this.mask.rotation.y = x * 0.5
    this.mask.rotation.x = y * -0.2
  }

  animate () {
    super.animate()

    const { x, y } = EasedMouse.normalizedPosition
    this.rotateMask(x, y)

    this.raf = window.requestAnimationFrame(this.animate.bind(this))
  }

  play () {
    this.animate()
  }

  pause () {
    window.cancelAnimationFrame(this.raf)
  }
}

export default App
