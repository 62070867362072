import EventEmitter from 'events'
import * as PIXI from 'pixi.js'
import { find, isFunction, remove } from 'lodash'

class Loader extends EventEmitter {
  loaders = []
  resources = {}

  loading ({ onProgress, onComplete }) {
    setTimeout(() => {
      const n = this.loaders.length || 1
      let loaded = 0

      const promises = this.loaders.map(item => {
        return new Promise(resolve => {
          item.onComplete(() => {
            loaded++
            isFunction(onProgress) && onProgress(loaded / n)
            resolve()
          })
        })
      })

      Promise.all(promises).then(() => {
        isFunction(onComplete) && onComplete()
        isFunction(onProgress) && onProgress(1)
      })
    }, 300)
  }

  get (id) {
    return find(this.loaders, { id })
  }

  add ({ id, src }) {
    if (!this.get(id)) {
      const callbacks = []
      const rejects = []

      const onComplete = callback => {
        isFunction(callback) && callbacks.push(callback)
      }

      const onReject = callback => {
        isFunction(callback) && rejects.push(callback)
      }

      const loader = {
        id,
        src,
        onComplete,
        onReject,
      }

      const promise = new Promise((resolve, reject) => {
        Object.assign(loader, {
          resolve,
          reject,
        })
      })

      const success = (...args) => {
        this.remove(id)

        while (callbacks.length) {
          callbacks.shift()(...args)
        }
      }

      const error = () => {
        while (rejects.length) {
          rejects.shift()()
        }
      }

      promise.then(success, error)

      this.loaders.push(loader)

      return loader
    }
  }

  loadTexture ({ id, src, callback }) {
    if (PIXI.utils.TextureCache[src]) {
      isFunction(callback) && callback(this.resources[id])

      return
    }

    let loader = this.get(id)

    if (!loader) {
      loader = this.add({ id, src })

      let PIXIloader = new PIXI.loaders.Loader()

      PIXIloader.add(src).load(() => {
        const { resources } = PIXIloader

        this.resources[id] = resources

        loader.resolve(resources)
      })

      const destroy = () => {
        PIXIloader.reset()
        PIXIloader.destroy()
        PIXIloader = null
      }

      loader.onComplete(destroy)
      loader.onReject(destroy)
    }

    loader.onComplete(callback)
  }

  remove (id) {
    const item = this.get(id)

    if (item) {
      const loaders = remove(this.loaders, loader => {
        return loader.id === id
      })

      loaders.forEach(loader => {
        loader.reject()
        loader = null
      })
    }
  }
}

const loader = new Loader()

export default loader
