import { PerspectiveCamera, Scene, WebGLRenderer } from 'three'
import EventEmitter from 'events'

class Core extends EventEmitter {
  constructor (canvas) {
    super()

    this._camera = new PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      1,
      5000
    )

    this._camera.position.z = window.innerWidth < 600 ? 100 : 90
    this.camera.initialPosition = this.camera.position.clone()

    this._scene = new Scene()
    this._renderer = new WebGLRenderer({ canvas, alpha: true })
    this._renderer.setPixelRatio(window.devicePixelRatio)
    this._renderer.setSize(window.innerWidth, window.innerHeight)
    this._renderer.setClearColor(0xffffff, 0.0)

    // const controls = new OrbitControls(this._camera, this._renderer.domElement)
    // controls.enabled = true
    // controls.maxDistance = 1500
    // controls.minDistance = 0

    window.addEventListener('resize', this.onWindowResize.bind(this), false)
  }
  get renderer () {
    return this._renderer
  }
  get camera () {
    return this._camera
  }
  get scene () {
    return this._scene
  }
  onWindowResize () {
    this._camera.aspect = window.innerWidth / window.innerHeight
    this._camera.updateProjectionMatrix()
    this._renderer.setSize(window.innerWidth, window.innerHeight)
  }
  animate (timestamp) {
    this.camera.lookAt(0, 0, 0)
    this._renderer.render(this.scene, this.camera)
  }
}
export default Core
