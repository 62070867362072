// SINGLETON
class EasedMouse {
  constructor ({ callbacks = [] } = {}) {
    if (EasedMouse.instance) {
      return EasedMouse.instance
    }

    EasedMouse.instance = this

    this.final = { x: 0, y: 0 }
    this.finalNormalized = { x: 0, y: 0 }
    this.mousePos = { x: 0, y: 0 }
    this.easeFactor = 0.02
    this.cbs = callbacks
    this.raf = undefined

    return this
  }

  init () {
    this.start()
  }

  start () {
    this.update()
  }

  get position () {
    return this.final
  }

  get normalizedPosition () {
    this.finalNormalized.x = (this.final.x / window.innerWidth) * 2 - 1
    this.finalNormalized.y = -(this.final.y / window.innerHeight) * 2 + 1
    return this.finalNormalized
  }

  update () {
    this.raf = window.requestAnimationFrame(this.update.bind(this))
    this.final.x += Math.floor(
      (this.mousePos.x - this.final.x) * this.easeFactor
    )
    this.final.y += Math.floor(
      (this.mousePos.y - this.final.y) * this.easeFactor
    )

    if (this.cbs.length >= 1) {
      for (let i = 0; i < this.cbs.length; i++) {
        this.cbs[i](this.final, this.finalNormalized)
      }
    }
  }

  updateMousePosition (x, y) {
    this.mousePos.x = x
    this.mousePos.y = y
  }

  addCallback (func) {
    this.cbs.push(func)
  }

  pause () {
    setTimeout(() => {
      window.cancelAnimationFrame(this.raf)
    }, 0)
  }
}

const instance = new EasedMouse()

export default instance
