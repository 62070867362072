import React from 'react'

import BaseLayout from '~/layouts/base'
import EasterEgg from '../../components/EasterEgg'

class EasterEggPage extends React.Component {
  render () {
    return (
      <BaseLayout>
        <EasterEgg />
      </BaseLayout>
    )
  }
}

export default EasterEggPage
