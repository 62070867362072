import React from 'react'
import { connect } from 'react-redux'
import { flowRight, after } from 'lodash'
import Hammer from 'hammerjs'
import { Actions } from '~/modules/action'
import EasterEggApp from '~/EasterEgg'

import bg from '~/media/easterEgg/bg.jpg'
import EasedMouse from '../EasterEgg/helpers/EasedMouse'
import { TweenLite } from 'gsap'
import loaderManager from '~/helpers/loaderManager'

class EasterEgg extends React.Component {
  canvas = React.createRef()
  background = React.createRef()

  easedMouse = EasedMouse

  constructor () {
    super()

    this.initControls = this.initControls.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onTouchMove = this.onTouchMove.bind(this)
  }

  componentDidMount () {
    this.easterEgg = new EasterEggApp(this.canvas.current)

    const onReady = after(2, () => {
      this.initControls()

      this.easedMouse.addCallback((mousepos, mouseNormalized) => {
        TweenLite.set(this.background.current, {
          xPercent: -50 - mouseNormalized.x * 2,
          yPercent: -50 + mouseNormalized.y * 2,
        })
      })
    })

    loaderManager.loadTexture({
      id: 'easteregg-bg',
      src: bg,
      callback: onReady,
    })

    this.easterEgg.on('ready', onReady)
  }

  componentDidUpdate (prevProps) {
    const { easterEgg } = this.props

    if (prevProps.easterEgg !== easterEgg) {
      this.toggle()
    }
  }

  initControls () {
    const { playEasterEgg, stopEasterEgg } = this.props

    const isG = ({ keyCode }) => keyCode === 71

    window.addEventListener('keydown', event => {
      const { intro } = this.props

      if (isG(event) && !intro) {
        playEasterEgg()
      }
    })

    window.addEventListener('keyup', event => {
      if (isG(event)) {
        stopEasterEgg()
      }
    })

    if ('ontouchstart' in document.documentElement) {
      const hammer = new Hammer(document, {
        domEvents: true,
      })

      hammer.get('press').set({
        time: 600,
      })

      const stop = () => {
        stopEasterEgg()
        document.removeEventListener('touchend', stop)
        document.removeEventListener('touchmove', this.onTouchMove)
      }

      hammer.on('press', e => {
        const { intro } = this.props

        if (!intro) {
          playEasterEgg()
          document.addEventListener('touchend', stop)
          document.addEventListener('touchmove', this.onTouchMove)
        }
      })
    }
  }

  toggle () {
    const { easterEgg } = this.props

    if (easterEgg) {
      this.easterEgg.play()
      this.easedMouse.start()
    } else {
      this.easterEgg.pause()
      this.easedMouse.pause()
    }
  }

  onMouseMove (e) {
    const { easterEgg } = this.props

    if (easterEgg) {
      this.easedMouse.updateMousePosition(e.clientX, e.clientY)
    }
  }

  onTouchMove (e) {
    const { easterEgg } = this.props

    const x = e.pageX ? e.pageX : e.targetTouches[0].pageX
    const y = e.pageY ? e.pageY : e.targetTouches[0].pageY

    if (easterEgg) {
      this.easedMouse.updateMousePosition(x, y)
    }
  }

  render () {
    const { easterEgg } = this.props

    return (
      <div
        className="wrapper"
        onMouseMove={this.onMouseMove}>
        <div
          className="background"
          ref={this.background} />
        <canvas
          ref={this.canvas}
          className="canvas" />

        <style jsx>{`
          .wrapper {
            z-index: 4;
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            overflow: hidden;
            display: ${ easterEgg ? 'block' : 'none' };

          }

          .background {
            position: fixed;
            top: 50%;
            left: 50%;
            height: 100vh;
            width: 100vw;
            background-image: url("${ bg }");
            background-size: cover;
            background-position: center;
            transform: scale(1.2);
          }
          .canvas {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        `}</style>
      </div>
    )
  }
}

const mapStateToProps = ({ isAppReady, easterEgg, intro }) => ({
  isAppReady,
  easterEgg,
  intro,
})

const mapDispatchToProps = dispatch => ({
  playEasterEgg: payload => dispatch(Actions.playEasterEgg(payload)),
  stopEasterEgg: payload => dispatch(Actions.stopEasterEgg(payload)),
})

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EasterEgg)
