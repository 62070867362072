import * as THREE from 'three'
import { loaderManager } from './loadingManager'

const txtCubeLoader = new THREE.CubeTextureLoader(loaderManager)

export default function load (basePath) {
  return new Promise(function (resolve) {
    let txt
    txt = txtCubeLoader.load(
      [
        require(`~/${ basePath }/px.jpg`),
        require(`~/${ basePath }/nx.jpg`),
        require(`~/${ basePath }/py.jpg`),
        require(`~/${ basePath }/ny.jpg`),
        require(`~/${ basePath }/pz.jpg`),
        require(`~/${ basePath }/nz.jpg`),
      ],
      () => {
        resolve(txt)
      }
    )
  })
}
