import { LoadingManager } from 'three'

const loaderManager = new LoadingManager()

const loaderManagerPromise = new Promise(function (resolve) {
  loaderManager.onLoad = function () {
    resolve()
  }
})

export { loaderManager, loaderManagerPromise }
