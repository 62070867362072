import * as THREE from 'three'
import { loaderManager } from './loadingManager'
import OBJLoader from 'three-obj-loader'
OBJLoader(THREE)

const objLoader = new THREE.OBJLoader(loaderManager)

export default function objload (model) {
  return new Promise(function (resolve) {
    objLoader.load(model, resources => {
      const mesh = resources.children[0]
      mesh.scale.set(50, 50, 50)
      resolve(mesh)
    })
  })
}
